// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';
import { AxiosError } from 'axios';

// <!-- UTILITIES -->
import { Result } from 'true-myth/dist/result';
import { ErrorResult } from '@/utils/server';

// <!-- MODELS -->
import { Standards } from '@/models/v2/standards';

// <!-- ROUTES -->

/** @type {Readonly<Standards.Routes>} */
const URI = {
    index: () => `nara/standards`,
    show: (id) => `nara/standards/${id}`,
    create: () => `nara/standards`,
    update: (id) => `nara/standards/${id}`,
    delete: (id) => `nara/standards/${id}`,
};

// <!-- ENDPOINTS -->

/**
 * Fetch all standards.
 * @type {Standards.API.FetchIndex}
 */
export const fetchStandardsIndex = async (config) => {
    try {
        /** @type {globalThis.Standards.Response.FetchedIndex} */
        const response = await axios().get(URI.index(), config);
        const payloads = response.data.data.map(Standards.Payload.create);
        const models = payloads.map(Standards.Model.fromPayload);
        const result = Result.ok(models);
        return result;
        // const result = account.toOkOrElseErr(() => {
        //     throw new Error('Unable to parse resource from endpoint response.');
        // });
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Fetch specific standards by id.
 * @type {Standards.API.FetchResource}
 */
export const fetchStandardsById = async (request, config) => {
    try {
        /** @type {globalThis.Standards.Response.FetchedResource} */
        const response = await axios().get(URI.show(request.id), config);
        const payload = Standards.Payload.create(response.data.standards);
        const model = payload.toModel();
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Create a single standards.
 * @type {Standards.API.CreateResource}
 */
export const createStandards = async (request, config) => {
    try {
        /** @type {globalThis.Standards.Response.CreatedResource} */
        const response = await axios().post(URI.create(), request, config);
        const payload = Standards.Payload.create(response.data.standards);
        const model = payload.toModel();
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Update a specific standards by id.
 * @type {Standards.API.UpdateResource}
 */
export const updateStandardsById = async (request, config) => {
    try {
        /** @type {globalThis.Standards.Response.UpdatedResource} */
        const response = await axios().patch(
            URI.update(request.id),
            request,
            config
        );
        const payload = Standards.Payload.create(response.data.standards);
        const model = payload.toModel();
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Delete a specific standards by id.
 * @type {Standards.API.DeleteResource}
 */
export const deleteStandardsById = async (request, config) => {
    try {
        /** @type {globalThis.Standards.Response.DeletedResource} */
        const response = await axios().delete(URI.delete(request.id), config);
        const result = Result.ok(response.status === 204);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/** @type {Readonly<Standards.API>} */
export default {
    fetchStandardsIndex,
    fetchStandardsById,
    createStandards,
    updateStandardsById,
    deleteStandardsById,
};
