// <!-- FORWARD EXPORTS -->
export { StandardsPayload } from '@/payloads/v2/standards/StandardsPayload';
export { StandardsModel } from './StandardsModel';

// <!-- IMPORT -->
import { StandardsPayload } from '@/payloads/v2/standards/StandardsPayload';
import { StandardsModel } from './StandardsModel';

// <!-- NAMESPACE EXPORTS -->
export const Standards = {
    Payload: StandardsPayload,
    Model: StandardsModel,
};
