// <!-- UTILITIES -->
import { Option } from './Option';
import { createEventHook } from '@vueuse/core';

// <!-- UTILITY -->
/**
 * Creates a base option representation.
 *
 * @template {string} [K=string]
 * @template {string} [V=string]
 * @extends {Option<K,V>}
 * @implements {Options.DropdownOptionRecord<K,V>}
 */
export class DropdownOption extends Option {
    // <!-- STATIC FACTORY METHODS -->

    /**
     * Create a single option.
     *
     * @template {string} Label
     * @template {string} Value
     * @param {Label} label
     * @param {Value} value
     * @param {Options.OptionAttributes} [attrs]
     * @returns
     */
    static create(label, value, attrs = null) {
        return new DropdownOption({ value, label, attrs: attrs ?? {} });
    }

    /**
     * Create a single pre-selected option.
     *
     * @template {string} Label
     * @template {string} Value
     * @param {Label} label
     * @param {Value|''} value
     * @param {boolean} [disabled]
     * @returns
     */
    static initial(label, value, disabled = false) {
        return DropdownOption.create(label, value, {
            disabled,
            selected: true,
        });
    }

    /**
     * Create a single placeholder option.
     *
     * @template {string} Label
     * @template {string} Value
     * @param {Label|'Select Option'} [label]
     * @param {Value|''} [value]
     * @param {boolean} [selected]
     * @returns
     */
    static placeholder(label = 'Select Option', value = '', selected = true) {
        return DropdownOption.create(label, value, {
            selected,
            disabled: true,
        });
    }

    /**
     * Create a single option from a tuple.
     *
     * @template {string} Label
     * @template {string} Value
     * @param {[ label: Label, value: Value | '', attrs?: Options.OptionAttributes ]} props
     * @returns
     */
    static fromTuple([label, value, attrs = null]) {
        return DropdownOption.create(label, value, attrs);
    }

    /**
     * Create a single option, using the same value for its label.
     *
     * @template {string} Value
     * @param {Value} value
     * @param {Options.OptionAttributes} [attrs]
     * @returns
     */
    static fromValue(value, attrs = null) {
        return DropdownOption.create(value, value, attrs);
    }

    /**
     * Create an array of options from a label-value record.
     *
     * @template {string} Label
     * @template {string} Value
     * @param {Record<Label,Value>} [record]
     * @param {Partial<Record<Label,Options.OptionAttributes>>} [attributes]
     * @returns
     */
    static fromRecord(record, attributes = {}) {
        const options = /** @type {Array<Option<Label,Value>>} */ ([]);
        for (const key in record) {
            options.push(
                new DropdownOption({
                    label: key,
                    value: record?.[key],
                    attrs: attributes?.[key],
                })
            );
        }
        return options;
    }

    /**
     * Create an array of options from a collection of single-option definitions.
     *
     * @template {string} Label
     * @template {string} Value
     * @param {Array<Options.OptionRecord<Label,Value>>} options
     * @param {Partial<Record<Label,Options.OptionAttributes>>} [attributes]
     */
    static fromList(options = [], attributes = {}) {
        return options.map(
            ({ label, value, attrs }) =>
                new DropdownOption({
                    label: label,
                    value: value,
                    attrs: {
                        ...attrs,
                        ...attributes?.[label],
                    },
                })
        );
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create an individual option representation.
     *
     * @param {Object} props
     * @param {K} props.label
     * @param {V} props.value
     * @param {Options.OptionAttributes} [props.attrs]
     */
    constructor(props) {
        // Initialize the readonly option values.
        super(props);

        /** @type {EventHook<Options.OptionRecord>} */
        const selected = createEventHook();

        /** @type {EventHookTrigger<Options.OptionRecord>} */
        this.select = selected.trigger;

        /** @type {EventHookOn<Options.OptionRecord>} */
        this.onSelected = selected.on;

        // Ensure event hook methods cannot be removed.
        Object.defineProperties(this, {
            select: {
                enumerable: true,
                configurable: false,
                writable: false,
            },
            onSelected: {
                enumerable: true,
                configurable: false,
                writable: false,
            },
        });
    }
}
