// <!-- INTERFACES -->
import { ModelResource } from '@/utils/resources';
import { ModelAttributeFactory } from '@/utils/resources';
import { StandardsPayload } from '@/payloads/v2/standards/StandardsPayload';

// <!-- ENUMS -->
import { TemperatureScale } from '@/enums';

/**
 * Create a specialized resource instance.
 *
 * @extends {ModelResource<PayloadData,ModelData>}
 * @implements {Resource.Model<PayloadData,ModelData>}
 * @implements {ModelData}
 */
export class StandardsModel extends ModelResource {
    // -----------------------------------------------------
    // TYPE ALIASES
    // -----------------------------------------------------

    /** @typedef {Standards.Payload} PayloadData */
    /** @typedef {Standards.Model} ModelData */

    // -----------------------------------------------------
    // STATIC FACTORIES
    // -----------------------------------------------------

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<ModelData>} attributes
     * @returns {StandardsModel}
     */
    static create(attributes) {
        return new StandardsModel(attributes);
    }

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<PayloadData>} data
     * @returns {StandardsModel}
     */
    static fromPayload(data) {
        const attributes = StandardsModel.attributesFromPayload(data);
        return StandardsModel.create(attributes);
    }

    // -----------------------------------------------------
    // STATIC PROPERTIES
    // -----------------------------------------------------

    /** @type {Readonly<ModelData>} */
    static get defaults() {
        return {
            id: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            standard: 'Standard',
            description: 'The default description for a NARA Standard.',
            minTemperature: -50,
            maxTemperature: 50,
            minRelativeHumidity: 0,
            maxRelativeHumidity: 100,
            temperatureScale: TemperatureScale.Fahrenheit,
        };
    }

    // -----------------------------------------------------
    // STATIC UTILITIES
    // -----------------------------------------------------

    /**
     * Create resource attributes from the payload data.
     *
     * @param {Partial<PayloadData>} payload
     * @returns {Partial<ModelData>}
     */
    static attributesFromPayload(payload) {
        // Get transformer functions.
        const { parse } = ModelResource;

        // Return the created state.
        return {
            id: payload.id,
            standard: payload.standard,
            description: payload.description,
            minTemperature: payload.min_temp,
            maxTemperature: payload.max_temp,
            minRelativeHumidity: payload.min_rh,
            maxRelativeHumidity: payload.max_rh,
            temperatureScale: payload.temperature_scale,
            createdAt: parse.dateString(payload.created_at),
            updatedAt: parse.dateString(payload.updated_at),
        };
    }

    // -----------------------------------------------------
    // CONSTRUCTOR
    // -----------------------------------------------------

    /**
     * Create resource instance.
     *
     * @param {Partial<ModelData>} attributes
     */
    constructor(attributes) {
        super(
            attributes,
            ModelAttributeFactory.create(
                StandardsModel.attributesFromPayload,
                StandardsModel.defaults
            )
        );
    }

    /** Displays the specified tag when printing to the console. */
    get [Symbol.toStringTag]() {
        return 'Standards\\Model';
    }

    // -----------------------------------------------------
    // RESOURCE INTERFACE
    // -----------------------------------------------------

    /** Get shallow copy of this instance as a resource payload. */
    toPayload() {
        return StandardsPayload.fromModel(this);
    }

    /** Get shallow copy of this instance as a resource model. */
    toModel() {
        return this.clone();
    }

    // -----------------------------------------------------
    // ATTRIBUTABLE INTERFACE
    // -----------------------------------------------------

    /** Get shallow copy of this instance. */
    clone() {
        return /** @type {this} */ (StandardsModel.create(this));
    }

    // -----------------------------------------------------
    // ATTRIBUTE::PROPERTIES
    // -----------------------------------------------------

    get id() {
        return this.get('id');
    }

    set id(value) {
        this.set('id', value);
    }

    get standard() {
        return this.get('standard');
    }

    set standard(value) {
        this.set('standard', value);
    }

    get description() {
        return this.get('description');
    }

    set description(value) {
        this.set('description', value);
    }

    get minTemperature() {
        return this.get('minTemperature');
    }

    set minTemperature(value) {
        this.set('minTemperature', value);
    }

    get maxTemperature() {
        return this.get('maxTemperature');
    }

    set maxTemperature(value) {
        this.set('maxTemperature', value);
    }

    get minRelativeHumidity() {
        return this.get('minRelativeHumidity');
    }

    set minRelativeHumidity(value) {
        this.set('minRelativeHumidity', value);
    }

    get maxRelativeHumidity() {
        return this.get('maxRelativeHumidity');
    }

    set maxRelativeHumidity(value) {
        this.set('maxRelativeHumidity', value);
    }

    get temperatureScale() {
        return this.get('temperatureScale');
    }

    set temperatureScale(value) {
        this.set('temperatureScale', value);
    }

    get createdAt() {
        return this.get('createdAt');
    }

    set createdAt(value) {
        this.set('createdAt', value);
    }

    get updatedAt() {
        return this.get('updatedAt');
    }

    set updatedAt(value) {
        this.set('updatedAt', value);
    }
}
