// <!-- INTERFACES -->
import { PayloadResource } from '@/utils/resources';
import { PayloadAttributeFactory } from '@/utils/resources';
import { StandardsModel } from '@/models/v2/standards/StandardsModel';

// <!-- ENUMS -->
import { TemperatureScale } from '@/enums';

/**
 * Create a specialized resource instance.
 *
 * @extends {PayloadResource<PayloadData,ModelData>}
 * @implements {Resource.Payload<PayloadData,ModelData>}
 * @implements {PayloadData}
 */
export class StandardsPayload extends PayloadResource {
    // -----------------------------------------------------
    // TYPE ALIASES
    // -----------------------------------------------------

    /** @typedef {Standards.Payload} PayloadData */
    /** @typedef {Standards.Model} ModelData */

    // -----------------------------------------------------
    // STATIC FACTORIES
    // -----------------------------------------------------

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<PayloadData>} attributes
     * @returns {StandardsPayload}
     */
    static create(attributes) {
        return new StandardsPayload(attributes);
    }

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<ModelData>} data
     * @returns {StandardsPayload}
     */
    static fromModel(data) {
        const attributes = StandardsPayload.attributesFromModel(data);
        return StandardsPayload.create(attributes);
    }

    // -----------------------------------------------------
    // STATIC PROPERTIES
    // -----------------------------------------------------

    /** @type {Readonly<PayloadData>} */
    static get defaults() {
        return {
            id: undefined,
            created_at: undefined,
            updated_at: undefined,
            standard: 'Standard',
            description: 'The default description for a NARA Standard.',
            min_temp: -50,
            max_temp: 50,
            min_rh: 0,
            max_rh: 100,
            temperature_scale: TemperatureScale.Fahrenheit,
        };
    }

    // -----------------------------------------------------
    // STATIC UTILITIES
    // -----------------------------------------------------

    /**
     * Create resource attributes from the model data.
     *
     * @param {Partial<ModelData>} model
     * @returns {Partial<PayloadData>}
     */
    static attributesFromModel(model) {
        // Get transformer functions.
        const { serialize } = this;

        // Return the created state.
        return {
            id: model.id,
            standard: model.standard,
            description: model.description,
            min_temp: model.minTemperature,
            max_temp: model.maxTemperature,
            min_rh: model.minRelativeHumidity,
            max_rh: model.maxRelativeHumidity,
            temperature_scale: model.temperatureScale,
            created_at: serialize.dateAsISOString(model.createdAt),
            updated_at: serialize.dateAsISOString(model.updatedAt),
        };
    }

    // -----------------------------------------------------
    // CONSTRUCTOR
    // -----------------------------------------------------

    /**
     * Create resource instance.
     *
     * @param {Partial<PayloadData>} attributes
     */
    constructor(attributes) {
        super(
            attributes,
            PayloadAttributeFactory.create(
                StandardsPayload.attributesFromModel,
                StandardsPayload.defaults
            )
        );
    }

    /** Displays the specified tag when printing to the console. */
    get [Symbol.toStringTag]() {
        return 'Standards\\Payload';
    }

    // -----------------------------------------------------
    // RESOURCE INTERFACE
    // -----------------------------------------------------

    /** Get shallow copy of this instance as a resource payload. */
    toPayload() {
        return this.clone();
    }

    /** Get shallow copy of this instance as a resource model. */
    toModel() {
        return StandardsModel.fromPayload(this);
    }

    // -----------------------------------------------------
    // ATTRIBUTABLE INTERFACE
    // -----------------------------------------------------

    /** Get shallow copy of this instance. */
    clone() {
        return /** @type {this} */ (StandardsPayload.create(this));
    }

    // -----------------------------------------------------
    // ATTRIBUTE::PROPERTIES
    // -----------------------------------------------------

    get id() {
        return this.get('id');
    }

    set id(value) {
        this.set('id', value);
    }

    get standard() {
        return this.get('standard');
    }

    set standard(value) {
        this.set('standard', value);
    }

    get description() {
        return this.get('description');
    }

    set description(value) {
        this.set('description', value);
    }

    get min_temp() {
        return this.get('min_temp');
    }

    set min_temp(value) {
        this.set('min_temp', value);
    }

    get max_temp() {
        return this.get('max_temp');
    }

    set max_temp(value) {
        this.set('max_temp', value);
    }

    get min_rh() {
        return this.get('min_rh');
    }

    set min_rh(value) {
        this.set('min_rh', value);
    }

    get max_rh() {
        return this.get('max_rh');
    }

    set max_rh(value) {
        this.set('max_rh', value);
    }

    get temperature_scale() {
        return this.get('temperature_scale');
    }

    set temperature_scale(value) {
        this.set('temperature_scale', value);
    }

    get created_at() {
        return this.get('created_at');
    }

    set created_at(value) {
        this.set('created_at', value);
    }

    get updated_at() {
        return this.get('updated_at');
    }

    set updated_at(value) {
        this.set('updated_at', value);
    }
}
