// <!-- UTILITIES -->
import is from '@sindresorhus/is';

// <!-- UTILITY -->
/**
 * Creates a base option representation.
 *
 * @template {string} [K=string]
 * @template {string} [V=string]
 * @implements {Options.OptionRecord<K,V>}
 */
export class Option {
    // <!-- STATIC FACTORY METHODS -->

    /**
     * Create a single option.
     *
     * @template {string} Label
     * @template {string} Value
     * @param {Label} label
     * @param {Value} value
     * @param {Options.OptionAttributes} [attrs]
     * @returns
     */
    static create(label, value, attrs = null) {
        return new Option({ value, label, attrs: attrs ?? {} });
    }

    /**
     * Create a single pre-selected option.
     *
     * @template {string} Label
     * @template {string} Value
     * @param {Label} label
     * @param {Value|''} value
     * @param {boolean} [disabled]
     * @returns
     */
    static initial(label, value, disabled = false) {
        return Option.create(label, value, { disabled, selected: true });
    }

    /**
     * Create a single placeholder option.
     *
     * @template {string} Label
     * @template {string} Value
     * @param {Label|'Select Option'} [label]
     * @param {Value|''} [value]
     * @param {boolean} [selected]
     * @returns
     */
    static placeholder(label = 'Select Option', value = '', selected = true) {
        return Option.create(label, value, { selected, disabled: true });
    }

    /**
     * Create a single option from a tuple.
     *
     * @template {string} Label
     * @template {string} Value
     * @param {[ label: Label, value: Value | '', attrs?: Options.OptionAttributes ]} props
     * @returns
     */
    static fromTuple([label, value, attrs = null]) {
        return Option.create(label, value, attrs);
    }

    /**
     * Create a single option, using the same value for its label.
     *
     * @template {string} Value
     * @param {Value} value
     * @param {Options.OptionAttributes} [attrs]
     * @returns
     */
    static fromValue(value, attrs = null) {
        return Option.create(value, value, attrs);
    }

    /**
     * Create an array of options from a label-value record.
     *
     * @template {string} Label
     * @template {string} Value
     * @param {Record<Label,Value>} [record]
     * @param {Partial<Record<Label,Options.OptionAttributes>>} [attributes]
     * @returns
     */
    static fromRecord(record, attributes = {}) {
        const options = /** @type {Array<Option<Label,Value>>} */ ([]);
        for (const key in record) {
            options.push(
                new Option({
                    label: key,
                    value: record?.[key],
                    attrs: attributes?.[key],
                })
            );
        }
        return options;
    }

    /**
     * Create an array of options from a collection of single-option definitions.
     *
     * @template {string} Label
     * @template {string} Value
     * @param {Array<Options.OptionRecord<Label,Value>>} options
     * @param {Partial<Record<Label,Options.OptionAttributes>>} [attributes]
     */
    static fromList(options = [], attributes = {}) {
        return options.map(
            ({ label, value, attrs }) =>
                new Option({
                    label: label,
                    value: value,
                    attrs: {
                        ...attrs,
                        ...attributes?.[label],
                    },
                })
        );
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create an individual option representation.
     *
     * @param {Object} props
     * @param {K} props.label
     * @param {V} props.value
     * @param {Options.OptionAttributes} [props.attrs]
     */
    constructor(props) {
        // Assign value property.
        this.value = /** @type {V} */ (props?.value);

        // Assign label property.
        this.label = /** @type {K} */ (props?.label);

        // Assign attributes property.
        this.attrs = {
            disabled: false,
            selected: false,
            ...props?.attrs,
        };

        // Ensure properties are readonly.
        Object.defineProperties(this, {
            value: {
                enumerable: true,
                configurable: false,
                writable: false,
            },
            label: {
                enumerable: true,
                configurable: false,
                writable: false,
            },
            attrs: {
                enumerable: true,
                configurable: true,
                writable: false,
            },
        });
    }

    /** Get the current value parsed as an integer number. Returns `NaN` if not a valid number. */
    get valueAsInt() {
        return is.numericString(this.value) ? Number.parseInt(this.value) : NaN;
    }

    /** Get the current value parsed as a floating-point number. Returns `NaN` if not a valid number. */
    get valueAsFloat() {
        return is.numericString(this.value)
            ? Number.parseFloat(this.value)
            : NaN;
    }

    get isEnabled() {
        return this.attrs?.disabled != true;
    }

    get isDisabled() {
        return this.attrs?.disabled == true;
    }

    get isSelected() {
        return this.attrs?.selected == true;
    }

    /**
     * Check if the passed label matches this instance's label.
     *
     * @param {string} label
     * @returns {label is K}
     */
    hasLabel(label) {
        return this.label === label;
    }

    /**
     * Check if the passed value matches this instance's value.
     *
     * @param {string} value
     * @returns {value is V}
     */
    hasValue(value) {
        return this.value === value;
    }

    /**
     * Execute a callback conditionally, based on this option's state:
     * - If `conditional` is a string, it will be compared against the value directly.
     *
     * The callback should not return anything.
     *
     * @param {string | ((option: this) => boolean)} conditional
     * @param {Lambda.Consumer<this>} [callback]
     * @returns {this}
     */
    when(conditional, callback = () => void 0) {
        // Prepare the callback parameters.
        const params = [this];

        // Get the option's value.
        const { value } = this;

        // If conditional is a string, check if it's equal to the current value.
        if (typeof conditional === 'string' && value === conditional) {
            // Alias as the predicate function.
            const target = conditional;
            // Evaluate the predicate.
            if (target === value) {
                // Execute the callback using this instance, when test is truthy.
                callback?.apply(this, params);
            }
        }

        // If conditional is a function, check if it evaluates to a truthy value.
        if (typeof conditional === 'function') {
            // Alias as the predicate function.
            const predicate = conditional;
            // Evaluate the predicate.
            if (predicate.apply(this, params) == true) {
                // Execute the callback using this instance, when test is truthy.
                callback?.apply(this, params);
            }
        }

        // Return self for fluent interface method chaining.
        return this;
    }
}
